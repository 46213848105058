import React, { useState } from "react";
import {
  Box,
  Typography,
  Stack,
} from "@mui/material";
import data from "../../constants/data";
import ServicesDesktop from "../common/ServicesDesktop";
import ServicesMobile from "../common/ServicesMobile";

const Services = () => {
  const servicesData = data.services;

  return (
    <Box
      sx={{
        padding: 2,
        marginRight: { xs: 1, sm: 7 },
        marginLeft: { xs: 1, sm: 7 },
        minHeight: "500px",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        marginTop: { xs: 10, sm: 5 },
      }}
    >
      <Box maxWidth="lg">
        <Stack sx={{ alignItems: "center", mb: 4 }}>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontWeight: { xs: 700, sm: 600 },
              fontSize: { xs: "26px", sm: "50px" },
              color: "#000",
              textAlign: "center",
              mb: 1,
              lineHeight: "normal",
              fontStyle: "normal",
            }}
          >
            {servicesData.title}
          </Typography>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: { xs: "18px", sm: "26px" },
              fontWeight: 400,
              color: "#363636",
              textAlign: "center",
              lineHeight: "normal",
              fontStyle: "normal",
            }}
          >
            {servicesData.description}
          </Typography>
        </Stack>
        <Box
          sx={{
            backgroundColor: "#F9F9F9",
            borderRadius: "80px",
            display: { xs: "none", sm: "block" },
          }}
        >
          <ServicesDesktop servicesData={servicesData} />
        </Box>

        <Box
          sx={{
            width: "100%",
            bgcolor: "#f9f9f9",
            display: { xs: "block", sm: "none" },
          }}
        >
          <ServicesMobile servicesData={servicesData} />
        </Box>
      </Box>
    </Box>
  );
};

export default Services;
