import {React, useState} from 'react';
import { Box, Container, Typography, Breadcrumbs, Link , Grid} from '@mui/material';
import { Helmet } from 'react-helmet';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import teamData from '../../constants/teamData';
import Team from './Team';
import BlueContainer from '../common/BlueContainer';
import aboutUsBanner from '../../assets/images/about-us-banner.png';
import visionIcon from '../../assets/images/vision-icon.svg';
import missionIcon from '../../assets/images/mission-icon.svg';

const AboutUs = () => {
    const [value, setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  
  return (
    <>
        <Helmet>
            <title>PRSTech.io | About Us</title>
            <meta name="description" content="Contact Us PRSTech.io" />
            <meta name="keywords" content="Contact Us PRSTech.io" />
            <meta name="author" content="PRShete Technologies Pvt. Ltd." />
        </Helmet>
        {/* <Container maxWidth="lg" sx={{ mt: 10 }}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href="/">Home</Link>
                <Typography color="text.primary">About</Typography>
            </Breadcrumbs>
        </Container> */}
         <Box
        sx={{
          marginRight: { xs: 1, sm: 7 },
          marginLeft: { xs: 1, sm: 7 },
          justifyContent: "center",
          marginTop: 10,
        }}
      >
         <Box
          sx={{
            position: "relative",
            background: `url(${aboutUsBanner}) white 50% / cover no-repeat`,
            width: "100%",
            height: { xs: 280, sm: 600 },
            justifyContent: "flex-start",
            textAlign: "left",
            paddingLeft: 3,
            paddingTop: { xs: 2, sm: 5 },
          }}
        >
          <Typography
            variant="h1"
            sx={{
              position: "absolute",
              top: "13%",
              left: "3%",
              fontFamily: "Poppins",
              fontWeight: 500,
              fontSize: { xs: 20, sm: 65 },
              color: "#191919",
              zIndex: 1,
            }}
          >
            About Us
          </Typography> 
        </Box>
        <Box sx={{ bgcolor: 'white', height: { lg: '500px', xs: 'auto' }, display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop:{xs:5, sm:0} }}>
            <Container maxWidth="lg">
                <Typography variant="body1" sx={{ fontFamily: 'Montserrat, sans-serif', fontWeight: 400, fontSize: { lg: 20, xs: 15 }, mt: 2, mb: 2 }}>
                    At PRSTech, we are dedicated to delivering innovative IT solutions tailored to meet the unique needs of our clients. With a focus on excellence and customer satisfaction, we strive to be the trusted partner that businesses can rely on for all their technology needs.
                    <br /><br />
                    Founded in <b>2016</b>, PRSTech has quickly grown into a leading provider of IT services, serving clients around the globe. Our team of skilled professionals brings together a wealth of experience and expertise in various areas of technology, allowing us to deliver cutting-edge solutions that drive business growth and success.
                    <br /><br />
                    Our commitment to excellence extends beyond just delivering great technology solutions. We also prioritize customer service and satisfaction, ensuring that our clients receive the highest level of support and attention throughout their engagement with us. With PRSTech, you can trust that you're not just getting a vendor – <b>you're getting a partner invested in your success</b>.
                </Typography>
            </Container>
        </Box>

        <Box
  sx={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    mb: 10,
    padding: '40px 20px', 
  }}
>
  <Container maxWidth="lg">
    <Grid
      container
      justifyContent="center" 
    >
      <Grid
        item
        xs={12}
        md={5}
        sx={{
          backgroundColor: '#F9F9F9',
          borderRadius: '30px',
          padding: '20px',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', 
        }}
      >
        <Box
          component="img"
          src={missionIcon}
          alt="Mission"
          sx={{ width: '100.27px', height: 'auto', mb: 2 }}
        />
        <Typography
          variant="h4"
          sx={{
            fontFamily: 'Poppins, sans-serif',
            fontWeight: 700,
            fontSize: { lg: 30, xs: 24 },
            mb: 1,
          }}
        >
          Mission
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontFamily: 'Montserrat, sans-serif',
            fontWeight: 400,
            fontSize: { lg: 20, xs: 15 },
            mb: 2,
          }}
        >
          To empower businesses with Performant, Robust and Secure software
          solutions that enhance efficiency, drive growth, and adapt to
          changing technological landscapes.
        </Typography>
      </Grid>
        <Grid
            item
            xs={0}
            md={1}
        
        >
        </Grid>

      <Grid
        item
        xs={12}
        md={5}
        sx={{
          backgroundColor: '#F9F9F9',
          borderRadius: '30px',
          padding: '20px',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', 
          marginTop: {xs: 4, sm:0 },
        }}
      >
        <Box
          component="img"
          src={visionIcon}
          alt="Vision"
          sx={{ width: '100.27px', height: 'auto', mb: 2 }}
        />
        <Typography
          variant="h4"
          sx={{
            fontFamily: 'Poppins, sans-serif',
            fontWeight: 700,
            fontSize: { lg: 30, xs: 24 },
            mb: 1,
          }}
        >
          Vision
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontFamily: 'Montserrat, sans-serif',
            fontWeight: 400,
            fontSize: { lg: 20, xs: 15 },
            mb: 2,
          }}
        >
          To be the most trusted partner in Tech journey, recognized for
          innovation, commitment, and excellence in delivering software
          solutions that shapes the future.
        </Typography>
      </Grid>
    </Grid>
  </Container>
</Box>


        </Box>
       <BlueContainer/>
        {/* <Box sx={{ bgcolor: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 5 }}>
            <Container maxWidth="lg">
                <Typography variant="h4" sx={{ fontFamily: 'Poppins, sans-serif', fontWeight: 700, fontSize: { lg: 30, xs: 24 }, mb: 2 }}>
                    Team
                </Typography>
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="Team tabs">
                                <Tab label="Tech" value="1" />
                                <Tab label="Leadership" value="2" />
                                <Tab label="Advisors" value="3" />
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            <Grid container spacing={2}>
                                {Object.entries(teamData.tech).map(([key, emp]) => (
                                    <Grid item xs={12} sm={6} md={3} key={emp.id}>
                                        <Team emp={emp} />
                                    </Grid>
                                ))}
                            </Grid>
                        </TabPanel>
                        <TabPanel value="2">
                            <Grid container spacing={2}>
                                {Object.entries(teamData.leadership).map(([key, emp]) => (
                                    <Grid item xs={12} sm={6} md={3} key={emp.id}>
                                        <Team emp={emp} />
                                    </Grid>
                                ))}
                            </Grid>
                        </TabPanel>
                        <TabPanel value="3">
                            <Grid container spacing={2}>
                                {Object.entries(teamData.advisors).map(([key, emp]) => (
                                    <Grid item xs={12} sm={6} md={3} key={emp.id}>
                                        <Team emp={emp} />
                                    </Grid>
                                ))}
                            </Grid>
                        </TabPanel>
                    </TabContext>
                </Box>
            </Container>
        </Box> */}
    </>
);
}

export default AboutUs;