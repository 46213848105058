import {
  RobertJan,
  GurinderMaan,
  PrashanthAdiyodi
} from "./images";


const testimonialsData = [
  {
    name: "Prashanth Adiyodi",
    company: "Rasoi Robotics, India",
    designation: "Founder",
    image:PrashanthAdiyodi,
    review:
      "Partnering for ROS development was a game-changer. The team's expertise accelerated our project timeline, providing robust solutions.",
    rating: 5,
  },
  {
    name: "Robert Jan Schouten,",
    company: "Lemon Ginger, Netherlands",
    designation: "MD",
    image:RobertJan,
    review:
      "With their skillful consultancy approach to tackle every challenge, they helped carve out a proposition for Lemon Ginger in order to gain the upper hand in the negotiation phase with a new project.",
    rating: 5,
  },
  {
    name: "Gurinder Maan",
    company: "Inspect Building, Australia",
    designation: "Founder",
    image: GurinderMaan,
    review:
      "Great team to work with, Digitised our home inspection process with a cross-platform user-friendly mobile application, prompt collaboration highly recommended ",
    rating: 5,
  },
];

export default testimonialsData;
