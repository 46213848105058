import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './components/home/Home';
import Layout from './components/common/layout';
import Industries from './components/industries/Industries';
import OurWorks from './components/ourworks/OurWorks';
import OurWorkPage from './components/ourworks/OurWorkPage';
import Resources from './components/resources/Resources';
import ResourcePage from './components/resources/ResourcePage';
import AboutUs from './components/aboutus/AboutUs';
import ContactUs from './components/contactus/ContactUs';
import IndustryPage from './components/industries/IndustryPage';
import Services from './components/services/Services';
import ServicePage from './components/services/ServicePage';
import NoPage from './components/nopage/index';
import BookCall from './components/contactus/bookCall'
import './i18n'
import ScrollToTop from './components/common/ScrollToTop';

const App = () => {
  
  return (
      <BrowserRouter>
      <ScrollToTop/>
          <Routes>
          <Route path="/" element={<Layout />}>
             <Route path="" element={<Home />}></Route> 
             <Route path="/resources" element={<Resources/>}></Route> 
             <Route path="/resources/:id" element={<ResourcePage/>}></Route>
             <Route path="/ourwork" element={<OurWorks/>}></Route> 
             <Route path="/ourwork/:id" element={<OurWorkPage/>}></Route>
             <Route path="/industries" element={<Industries/>}></Route> 
             <Route path="/industries/:id" element={<IndustryPage/>}></Route> 
             <Route path="/services" element={<Services/>}></Route> 
             <Route path="/services/:id" element={<ServicePage/>}></Route> 
             <Route path="/about" element={<AboutUs/>}></Route>
             <Route path="/contact" element={<ContactUs/>}></Route>
             <Route path="/bookcall" element={<BookCall/>} />
             <Route path="*" element={<NoPage />} />
           </Route>   
          </Routes>
      </BrowserRouter>
  );
};

export default App;