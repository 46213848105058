import React from 'react';
import { Box, Grid, Typography, Link, Container } from '@mui/material';
import { capitalize } from '@mui/material';
import footerImage from '../../assets/images/footer-img.png'; 
import data from '../../constants/data';
import europe from '../../assets/images/europe.svg';
import india from '../../assets/images/india.svg';
import XIcon from '@mui/icons-material/X';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
const socialIcons = [
  { name: 'linkedin', href: 'https://www.linkedin.com/company/prstech/', icon: <LinkedInIcon /> },
  { name: 'youtube', href: 'https://x.com/prstech_io', icon: <XIcon/>},
];

function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: 'background.paper',
        px: 2,
        mt: 4,
        overflow: 'hidden',
      }}
    >
      <Container maxWidth="lg">
        <Typography
          sx={{
            color: '#000',
            fontWeight: 700,
            fontSize: 36,
            fontFamily: 'Poppins',
          }}
        >
          PRSTech
        </Typography>
        <Grid container spacing={5}>
          <Grid item xs={12} md={8}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Box>
                  <Typography
                    sx={{
                      color: '#000',
                      fontFamily: 'Poppins',
                      fontWeight: 400,
                      fontSize: '26.545px',
                      mb: 2,
                    }}
                  >
                    Quick Access
                  </Typography>
                  <Grid container spacing={3}>
                    <Grid item xs={6} sm={4}>
                      <Box>
                        {Object.entries(data)
                          .slice(1, 4)
                          .map(([key, menuItem]) => {
                            if (menuItem.active) {
                              const url = menuItem.url === '' ? '/' : `/${menuItem.url}`;
                              return (
                                <Link
                                  key={key}
                                  href={url}
                                  sx={{
                                    display: 'block',
                                    color: '#37474F',
                                    textDecoration: 'none',
                                    fontSize: 16,
                                    fontWeight: 400,
                                    fontFamily: 'Poppins',
                                    mb: 1,
                                  }}
                                >
                                  {capitalize(key)}
                                </Link>
                              );
                            }
                            return null;
                          })}
                      </Box>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Box>
                        {Object.entries(data)
                          .slice(4, 7)
                          .map(([key, menuItem]) => {
                            if (menuItem.active) {
                              const url = menuItem.url === '' ? '/' : `/${menuItem.url}`;
                              return (
                                <Link
                                  key={key}
                                  href={url}
                                  sx={{
                                    display: 'block',
                                    color: '#37474F',
                                    textDecoration: 'none',
                                    fontSize: 16,
                                    fontWeight: 400,
                                    fontFamily: 'Poppins',
                                    mb: 1,
                                  }}
                                >
                                  {capitalize(key)}
                                </Link>
                              );
                            }
                            return null;
                          })}
                      </Box>
                      
                    </Grid>
                  </Grid>
                  <Box sx={{ display: 'flex' }}>
                    {socialIcons.map((icon) => (
                      <Link
                      key={icon.name}
                      href={icon.href}
                      sx={{
                        display: 'inline-flex',
                        width: 45, 
                        height: 45, 
                        borderRadius: '50%', 
                        backgroundColor: '#F2F2F2', 
                        alignItems: 'center', 
                        justifyContent: 'center', 
                        textAlign: 'center', 
                        flexShrink: 0, 
                        marginRight: 1,
                        '&:hover': {
                          backgroundColor: 'white',
                        },
                      }}
                      target="_blank"
                    >
                      {icon.icon}
                    </Link>
                    ))}
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box>
                  <Typography
                    sx={{
                      color: '#000',
                      fontSize: '26.545px',
                      fontFamily: 'Poppins',
                      fontWeight: 400,
                      mb: 2,
                    }}
                  >
                    Office Location
                  </Typography>
                  <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 4, md: 3}}>
                    <Grid item xs={1.5}>
                    <img
                      src={europe} 
                      alt={"europe icon"}
                      style={{ width: 30, height: 30}}
                    />
                    </Grid>
                    <Grid item xs={10.5}>
                    <Typography
                    sx={{
                      color: '#37474F',
                      fontSize: 18,
                      fontWeight: 600,
                      fontFamily: 'Poppins',
                    }}
                  >
                    Europe <br/>
                    The Netherlands
                  </Typography>
                    <Typography
                    sx={{
                      color: '#37474F',
                      fontSize: 16,
                      fontWeight: 400,
                      fontFamily: 'Poppins',
                      mb: 2,
                    }}
                  >
                      Hoofdweg, 1058AW, Amsterdam, Noord Holland, The Netherlands.
                  </Typography>
                    </Grid>
                    <Grid item xs={1.5}>
                    <img
                      src={india} 
                      alt={"india icon"}
                      style={{ width: 30, height: 30}}
                    />
                    </Grid>
                    <Grid item xs={10.5}>
                    <Typography
                    sx={{
                      color: '#37474F',
                      fontSize: 18,
                      fontWeight: 600,
                      fontFamily: 'Poppins',
                    }}
                  >
                    India <br/>
                    Pune
                  </Typography>
                    <Typography
                    sx={{
                      color: '#37474F',
                      fontSize: 16,
                      fontWeight: 400,
                      fontFamily: 'Poppins',
                      mb: 2,
                    }}
                  >
                       113, Sai Vision, Pimple Saudagar, Pune, India, 411027
                    
                  </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>

            <Box sx={{ mt: { xs: 1, sm: 5 }, borderTop: '1px solid #C2C2C2' }}>
              <Typography
                sx={{
                  color: '#000',
                  fontWeight: 400,
                  fontSize: 16,
                  fontFamily: 'Poppins',
                  mt: 3,
                }}
              >
                &copy; {new Date().getFullYear()}{' '}
                     PRS Tech B.V.
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={4}>
            <Box
              component="img"
              src={footerImage}
              alt="Footer Image"
              sx={{
                width: '100%',
                objectFit: 'contain',
                borderRadius: 2,
                display: { xs: 'none', sm: 'block' },  
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Footer;
