import React, { useState, useEffect, useRef } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { Button, Avatar, Typography, Menu, MenuItem, IconButton, Paper } from '@mui/material';
import { Link } from 'react-router-dom';
import SwipeableTemporaryDrawer from './drawer';
import data from '../../constants/data';
import prs from '../../assets/images/prstech.png';
import dropdownArrow from '../../assets/images/down-arrow.svg';
import { useTranslation } from 'react-i18next';
import enLanguage from '../../assets/images/enLanguage.svg';
import deLanguage from '../../assets/images/deLanguage.svg';
import frLanguage from '../../assets/images/frLanguage.svg';


function Header() {
  const [languageAnchorEl, setLanguageAnchorEl] = useState(null);
  const [activeRoute, setActiveRoute] = useState(window.location.pathname);
  const [selectedLanguage, setSelectedLanguage] = useState('English');
  const [activeMenu, setActiveMenu] = useState(null);

  const menuRef = useRef(null); 

  const handleMenuClick = (menuKey) => {
    if (activeMenu === menuKey) {
      setActiveMenu(null);
    } else {
      setActiveMenu(menuKey);
    }
  };

  const handleMenuClose = () => {
    setActiveMenu(null);
  };

  const handleLanguageMenuOpen = (event) => {
    setLanguageAnchorEl(event.currentTarget);
  };

  const handleLanguageMenuClose = () => {
    setLanguageAnchorEl(null);
  };

  const handleRouteClick = (url) => {
    setActiveRoute(url);
  };

  const { i18n } = useTranslation();

  const handleLanguageChange = (language) => {
    if (i18n) {
      i18n.changeLanguage(language);
      setSelectedLanguage(language === 'en' ? 'English' :
        language === 'de' ? 'German' :
        language === 'fr' ? 'French' : 'English');
      handleLanguageMenuClose();
    } else {
      console.error('i18n is not initialized');
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setActiveMenu(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  return (
    <AppBar position="fixed" sx={{ backgroundColor: 'white', boxShadow: 0 }}>
      <Toolbar disableGutters>
        <Link to="/">
          <Avatar
            variant="square"
            alt="Purushottam Shete"
            src={prs}
            sx={{
              width: 160,
              height: 40,
              marginLeft: 7,
              mr: 0,
              display: { xs: 'none', md: 'flex' }
            }}
          />
        </Link>
        <Link to="/">
          <Avatar
            variant="square"
            alt="Purushottam Shete"
            src={prs}
            sx={{
              width: 120,
              height: 30,
              ml: 2,
              mr: 1,
              display: { xs: 'flex', md: 'none' }
            }}
          />
        </Link>

        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none', justifyContent: 'flex-end' } }}>
          <SwipeableTemporaryDrawer data={data} />
        </Box>

        <Box sx={{ flexGrow: 1, marginRight: 7, display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-end' }}>
          {Object.entries(data).map(([key, route]) => {
            if (route.active) {
              const url = route.url === '' ? '/' : `/${route.url}`;
              const isActive = activeRoute === url;

              if (key === 'services' || key === 'industries') {
                const menuItems = key === 'services' ? data.services.sub : data.industries.sub;

                return (
                  <div key={key} style={{ position: 'relative' }}>
                    <Button
                      sx={{
                        my: 2,
                        color: isActive ? '#0BA0E0' : 'black',
                        display: 'block',
                        fontWeight: isActive ? 'bold' : 'normal',
                        textTransform: 'capitalize',
                      }}
                      onClick={() => handleMenuClick(key)}
                    >
                      {key}
                      <img
                        src={dropdownArrow}
                        alt="dropdown arrow"
                        style={{ width: 16, height: 16, marginLeft: 8, paddingTop: 7 }}
                      />
                    </Button>

                    {activeMenu === key && (
                    <Paper
                      ref={menuRef} 
                      sx={{
                        padding: 0,
                        borderRadius: '13px 13px 10px 10px',  
                        boxShadow: '0px 4px 11.2px 0px rgba(135, 135, 135, 0.25)',
                        zIndex: 1300,
                        position: 'absolute',
                        top: '100%',
                        left: 0,
                        width: 'auto',
                        transform: 'translateY(5px)',
                        '&:hover': {
                          backgroundColor: '#f5f5f5', 
                          borderRadius: 'inherit'
                        },
                      }}
                    >
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        {Object.entries(menuItems).map(([subKey, subValue], index) => {
                          const baseUrl = key === 'services' ? '/services/' : '/industries/';
                          const itemUrl = `${baseUrl}${subValue.url}`;

                          return (
                            <a key={index} href={itemUrl} style={{ textDecoration: 'none' }}>
                              <MenuItem
                                onClick={() => handleMenuClose()}
                                sx={{
                                  color: 'black',
                                  fontFamily: 'Poppins, sans-serif',
                                  fontSize: '16px',
                                  padding: '10px 14px',
                                  display: 'flex',
                                  gap: '10px',
                                  '&:hover': {
                                    backgroundColor: '#0aa0e0',
                                    fontWeight: 'bold',
                                    color: '#FFFFFF',
                                    borderRadius: 'inherit',
                                  },
                                }}
                              >
                                <img src={subValue.icon} alt="icon" style={{ width: 30 }} />
                                <Typography>{subKey}</Typography>
                              </MenuItem>
                            </a>
                          );
                        })}
                      </Box>
                    </Paper>
                  )}

                  </div>
                );
              }

              return (
                <Button
                  key={key}
                  href={url}
                  sx={{
                    my: 2,
                    color: isActive ? '#0BA0E0' : 'black',
                    display: 'block',
                    fontWeight: isActive ? 'bold' : 'normal',
                    textTransform: 'capitalize',
                  }}
                  onClick={() => handleRouteClick(url)}
                >
                  {key}
                </Button>
              );
            }
            return null;
          })}

            <Box sx={{ display: { md: 'flex' }, display: 'flex', justifyContent: 'center', alignItems: 'center', ml: 4  }}>
              <Box>
                <Typography
                  variant="body1"
                  sx={{ color: 'black' }}
                  // onClick={handleLanguageMenuOpen}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', fontWeight:400, fontSize: 16 }}>
                    <img
                      src={selectedLanguage === 'English' ? enLanguage : selectedLanguage === 'German' ? deLanguage : frLanguage} 
                      alt={selectedLanguage}
                      style={{ width: 20, height: 20, marginRight: 10 }}
                    />
                    {selectedLanguage}
                    <img
                      src={dropdownArrow}
                      alt="dropdown arrow"
                      style={{ width: 15, marginLeft: 8,}}
                    />
                  </Box>
                </Typography>
              </Box>

              <Menu
              disabled={true}
                anchorEl={languageAnchorEl}
                open={Boolean(languageAnchorEl)}
                onClose={handleLanguageMenuClose}
                sx={{
                  '& .MuiMenu-paper': {
                    borderRadius: '13px 13px 10px 10px',  
                    backgroundColor: '#FFF',
                    boxShadow: '0px 4px 11.2px 0px rgba(135, 135, 135, 0.25)',
                  }
                }}
              >
                <MenuItem onClick={() => handleLanguageChange('en')} selected={selectedLanguage === 'English'}>
                  <Box sx={{ display: 'flex', alignItems: 'center', color: '#000', fontSize:{xs: 14, sm:16}, fontWeight:400, lineHeight: '30px', fontFamily: 'Poppins', mt:1  }}>
                    <img
                      src={enLanguage} 
                      alt="English"
                      style={{ width: 30, height: 30, marginRight: 10 }}
                    />
                    English (US)
                  </Box>
                </MenuItem>
                <MenuItem onClick={() => handleLanguageChange('de')} selected={selectedLanguage === 'German'}>
                  <Box sx={{ display: 'flex', alignItems: 'center',  color: '#000',  fontSize:{xs: 14, sm:16}, fontWeight:400, lineHeight: '45px', fontFamily: 'Poppins' }}>
                    <img
                      src={deLanguage} 
                      alt="German"
                      style={{ width: 30, height: 30, marginRight: 10 }}
                    />
                    German
                  </Box>
                </MenuItem>
                <MenuItem onClick={() => handleLanguageChange('fr')} selected={selectedLanguage === 'French'}>
                  <Box sx={{ display: 'flex', alignItems: 'center',  color: '#000',  fontSize:{xs: 14, sm:16}, fontWeight:400, lineHeight: '45px', fontFamily: 'Poppins' }}>
                    <img
                      src={frLanguage} 
                      alt="French"
                      style={{ width: 30, height: 30, marginRight: 10 }}
                    />
                    French
                  </Box>
                </MenuItem>
              </Menu>
            </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
