import React from "react";
import { Box, Typography, Stack } from "@mui/material";
import { Helmet } from "react-helmet";
import data from "../../constants/data";
import OurWorksCarousel from "../ourworks/OurWorksCarousel";
import ResourcesCarousel from "../resources/ResourcesCarousel";
import ServicesDesktop from "../common/ServicesDesktop";
import ServicesMobile from "../common/ServicesMobile";
import BlueContainer from "../common/BlueContainer";


const Services = () => {

    const servicesData = data.services;
    const ourworkData = data.ourwork;
    const resourcesData = data.resources
  
  return (
    <>
      <Helmet>
        <title>Prshete.com | Services </title>
        <meta
          name="description"
          content="Freelancer Python Backend Developer"
        />
        <meta
          name="keywords"
          content="Freelancer Python Backend Developer, Python Remote Backend Developer"
        />
        <meta name="author" content="Purushottam Shete" />
      </Helmet>
      <Box
        sx={{
          marginRight: { xs: 1, sm: 7 },
          marginLeft: { xs: 1, sm: 7 },
          justifyContent: "center",
          marginTop: 10,
        }}
      >
          <Box
          sx={{
            position: "relative",
            background: `url(${servicesData.image_banner}) white 50% / cover no-repeat`,
            width: "100%",
            height: { xs: 188, sm: 600 },
            justifyContent: "flex-start",
            textAlign: "left",
            paddingLeft: 3,
            paddingTop: { xs: 2, sm: 5 },
          }}
        >
          <Typography
            variant="h1"
            sx={{
              position: "absolute",
              top: "13%",
              left: "3%",
              fontFamily: "Poppins",
              fontWeight: 500,
              fontSize: { xs: 20, sm: 44 },
              color: "#191919",
              zIndex: 1,
            }}
          >
            {servicesData.title_banner}
          </Typography> 
        </Box>
        <Stack sx={{ alignItems: "center", mb: 4, mt: {xs:5,sm:10} }}>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontWeight: { xs: 700, sm: 600 },
              fontSize: { xs: "26px", sm: "50px" },
              color: "#000",
              textAlign: "center",
              mb: 1,
              lineHeight: "normal",
              fontStyle: "normal",
            }}
          >
            {servicesData.title}
          </Typography>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: { xs: "18px", sm: "26px" },
              fontWeight: 400,
              color: "#363636",
              textAlign: "center",
              lineHeight: "normal",
              fontStyle: "normal",
            }}
          >
            {servicesData.description}
          </Typography>
        </Stack>
      <Box
          sx={{
            backgroundColor: "#F9F9F9",
            borderRadius: "80px",
            marginBottom: 8,
            display: {xs: 'none', sm: 'block'}
          }}
        >
         
          <ServicesDesktop servicesData={servicesData} />
        </Box>

        <Box sx={{ width: "100%", bgcolor: "#f9f9f9", display: { xs: 'block', sm: 'none' }, mt:5 }}>
           <ServicesMobile servicesData={servicesData} />
      </Box>
      </Box>
        <OurWorksCarousel data={ourworkData}/>
        <ResourcesCarousel data={resourcesData}/>
       <BlueContainer/>
    </>
  );
};

export default Services;
