import React from "react";
import {
  Container,
  Typography,
  Breadcrumbs,
  Link,
  Box,
  Stack,
  Grid,
} from "@mui/material";
import { Helmet } from "react-helmet";
import data from "../../constants/data";
import { useParams } from "react-router-dom";
import OurWorksCarousel from "../ourworks/OurWorksCarousel";
import ResourcesCarousel from "../resources/ResourcesCarousel";
import BlueContainer from "../common/BlueContainer";

const IndustryPage = () => {
  const { id } = useParams();

  const ourworkData = data.ourwork;
  const resourcesData = data.resources;
  const industriesData = data.industries.sub;
  const industry = Object.values(industriesData).find(
    (item) => item.url === id
  );

  return (
    <>
      <Helmet>
        <title>Prshete.com | Industries </title>
        <meta
          name="description"
          content="Freelancer Python Backend Developer"
        />
        <meta
          name="keywords"
          content="Freelancer Python Backend Developer, Python Remote Backend Developer"
        />
        <meta name="author" content="Purushottam Shete" />
      </Helmet>
      <Box
        sx={{
          marginRight: { xs: 1, sm: 7 },
          marginLeft: { xs: 1, sm: 7 },
          justifyContent: "center",
          marginTop: 10,
        }}
      >
        <Box
          sx={{
            position: "relative",
            background: `url(${industry.image_banner}) white 50% / cover no-repeat`,
            width: "100%",
            height: { xs: 280, sm: 600 },
            justifyContent: "flex-start",
            textAlign: "left",
            paddingLeft: 3,
            paddingTop: { xs: 2, sm: 5 },
          }}
        >
          <Typography
            variant="h1"
            sx={{
              position: "absolute",
              top: "13%",
              left: "3%",
              fontFamily: "Poppins",
              fontWeight: 500,
              fontSize: { xs: 20, sm: 65 },
              color: "#191919",
              zIndex: 1,
            }}
          >
           {industry.title}
          </Typography>
        </Box>
        <Box sx={{ width: "100%", mt:{xs:4, sm:8} }}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12} sm={6}>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontWeight: 300,
                  fontSize: {xs:24, sm:40},
                  color: "#595757",
                  lineHeight: {xs:'30px',sm:"50px"},
                  fontStyle: "normal",
                }}
              >
                {industry.description_banner_left}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} sx={{mt:{xs:4,sm:0}}}>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontWeight: 300,
                  fontSize: 16,
                  color: "#000",
                  lineHeight: "30px",
                  fontStyle: "normal",
                }}
              >
                {industry.description_banner_right}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
     
      <OurWorksCarousel data={ourworkData} />
      <ResourcesCarousel data={resourcesData} />
      <BlueContainer />
    </>
  );
};

export default IndustryPage;
