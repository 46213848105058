import React from "react";
import { Card, CardMedia, Typography, Box } from "@mui/material";
import { Link } from "react-router-dom";

const Industry = ({ industry }) => {
  return (
    <Card
      sx={{
        width: "100%",
        borderRadius: "36px",
        backgroundColor: "#F9F9F9",
        position: "relative",
        overflow: "hidden",
        boxShadow: 2,
      }}
    >
      <CardMedia
        component="img"
        height="225"
        image={industry.image_services}
        alt={industry.title}
        sx={{
          borderRadius: "32px",
          objectFit: "cover",
          padding: 2,
        }}
      />

      <Box sx={{ padding: "16px", position: "relative" }}>
        <Typography
          variant="h6"
          sx={{
            fontFamily: "Poppins",
            fontWeight: "600",
            fontSize: "18px",
            color: "#000",
            marginBottom: "3px",
          }}
        >
          {industry.title}
        </Typography>

        <Typography
          variant="body2"
          sx={{
            fontFamily: "Poppins",
            fontWeight: "400",
            fontSize: "14px",
            color: "#363636",
            marginBottom: "16px",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            WebkitLineClamp: 2,
            textOverflow: "ellipsis",
          }}
        >
          {industry.description_banner_left}
        </Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Link
            to={industry.url}
            style={{
              textDecoration: "none",
              fontFamily: "Poppins",
              fontWeight: "bold",
              fontSize: "16px",
              color: "#0BA0E0",
            }}
          >
            Read more
          </Link>
        </Box>
      </Box>
    </Card>
  );
};

export default Industry;
