import React,{useState, useEffect} from "react";
import { Box, Container, Stack, Typography, Grid, Button } from "@mui/material";
import Resource from "./Resource";
import Slider from "react-slick";

const ResourcesCarousel = ({ data }) => {
  const sliceData = Object.entries(data.sub);
  const [slidesToShow, setSlidesToShow] = useState(3);
    const [currentSlide, setCurrentSlide] = useState(0);

    useEffect(() => {
        const updateSlidesToShow = () => {
            const width = window.innerWidth;
            if (width < 600) {
                setSlidesToShow(1);
            } else if (width < 960) {
                setSlidesToShow(2);
            } else {
                setSlidesToShow(3);
            }
        };

        updateSlidesToShow();
        window.addEventListener("resize", updateSlidesToShow);
        return () => {
            window.removeEventListener("resize", updateSlidesToShow);
        };
    }, []);

    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      arrows: false,
      slidesToShow,
      slidesToScroll: slidesToShow, 
      autoplay: false,
      autoplaySpeed: 5000,
      pauseOnHover: false,
      beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex),
      appendDots: (dots) => (
          <Box sx={{ display: "flex", justifyContent: "center", marginTop: 3 }}>
              {dots}
          </Box>
      ),
      customPaging: (i) => (
          <Box
              sx={{
                  width: 12,
                  height: 12,
                  borderRadius: "50%",
                  backgroundColor: i === Math.floor(currentSlide / slidesToShow) ? "#019AE5" : "#C4C4C4",
                  transition: "background-color 0.3s ease",
                  "&:hover": {
                      backgroundColor: "#019AE5",
                  },
              }}
          />
      ),
  };

  return (
    <Box
      sx={{
        padding: 2,
        marginTop: {xs: 10, sm: 5},
        ml: { xs: 0, sm: 5 }, mr: { xs: 0, sm: 5 }

      }}
    >
      <Box >
        <Stack sx={{ alignItems: "center", mb: 2 }}>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontWeight: { xs: 700, sm: 600 },
              fontSize: { xs: "26px", sm: "50px" },
              color: "#1D1D1E",
              textShadow: 2,
              lineHeight: "normal",
              fontStyle: "normal",
            }}
          >
            {data.title}
          </Typography>
          <Typography
             align="center"
             sx={{
               fontSize: { xs: "18px", sm: "26px" },
               fontWeight: 400,
               fontStyle: "normal",
               lineHeight: "normal",
               color: "#363636",
               marginBottom: 4,
             }}
          >
            {data.description}
          </Typography>
        </Stack>

        <Slider {...settings}>
          {sliceData.map(([key, blog]) => (
            <Grid item xs={12} sm={12} md={4} key={blog.id}  sx={{ padding: "5px 5px" }}>
              <Resource blog={blog} />
            </Grid>
          ))}
          </Slider>

        <Box display="flex" justifyContent="center" p={2} mt={6}>
          <Button
            href="/resources"
            sx={{
              backgroundColor: "#011B6E",
              marginTop: 5,
              color: "white",
              padding: {xs:"10px 70px",sm:"14px 84px"},
              fontSize: 20,
              fontFamily: "Poppins",
              fontWeight: 600,
              borderRadius: 30,
              "&:hover": {
                backgroundColor: "#019AE5",
              },
            }}
          >
            View All
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ResourcesCarousel;
