import React from 'react'
import { Container, Typography, Breadcrumbs, Link, Box, Stack, Grid } from '@mui/material';
import { Helmet } from 'react-helmet';
import data from '../../constants/data';
import OurWorksCarousel from '../ourworks/OurWorksCarousel';
import ResourcesCarousel from '../resources/ResourcesCarousel';
import BlueContainer from '../common/BlueContainer';
import ServicesDesktop from '../common/ServicesDesktop';
import ServicesMobile from '../common/ServicesMobile';
import Industry from './Industry';
const Industries = () => {
    
    const industriesData = data.industries;
    const ourworkData = data.ourwork;
    const resourcesData = data.resources;
    const servicesData = data.services;

    return (
        <>
        <Helmet>
          <title>Prshete.com | Industries </title>
          <meta name="description" content="Freelancer Python Backend Developer" />
          <meta name="keywords" content="Freelancer Python Backend Developer, Python Remote Backend Developer" />
          <meta name="author" content="Purushottam Shete" />
        </Helmet>
       
        <Box
        sx={{
          marginRight: { xs: 1, sm: 7 },
          marginLeft: { xs: 1, sm: 7 },
          justifyContent: "center",
          marginTop: 10,
        }}
      >
          <Box
          sx={{
            position: "relative",
            background: `url(${industriesData.image_banner}) white 50% / cover no-repeat`,
            width: "100%",
            height: { xs: 188, sm: 600 },
            justifyContent: "flex-start",
            textAlign: "left",
            paddingLeft: 3,
            paddingTop: { xs: 2, sm: 5 },
          }}
        >
          <Typography
            variant="h1"
            sx={{
              position: "absolute",
              top: "13%",
              left: "3%",
              fontFamily: "Poppins",
              fontWeight: 500,
              fontSize: { xs: 20, sm: 44 },
              color: "#191919",
              zIndex: 1,
            }}
          >
            {industriesData.title_banner}
          </Typography> 
        </Box>
          <Box sx={{ alignItems: "center", mb: 4, mt: {xs:5,sm:10} }}>
          <Grid container columnSpacing={10} spacing={4}>
              {Object.entries(industriesData.sub).map(([key, industry]) => (
                <Grid item xs={12} sm={6} md={6} key={industry.id}>
                  <Industry industry={industry} />
                </Grid>
              ))}
          </Grid>
          </Box>
      <Box
          sx={{
            backgroundColor: "#F9F9F9",
            borderRadius: "80px",
            my: 10,
            display: {xs: 'none', sm: 'block'}
          }}
        >
          <ServicesDesktop servicesData={servicesData}/>
        </Box>

        <Box sx={{ width: "100%", bgcolor: "#f9f9f9", display: { xs: 'block', sm: 'none' }, my:5 }}>
           <ServicesMobile servicesData={servicesData}  />
      </Box>
      </Box>
        <OurWorksCarousel data={ourworkData}/>
        <ResourcesCarousel data={resourcesData}/>
        <BlueContainer/>
    </>
    );
}

export default Industries;
