import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";

const OurWork = ({ caseStudy }) => {
  return (
    <Card
      sx={{
        width: "100%",
        borderRadius: "36px",
        backgroundColor: "#F9F9F9",
        position: "relative",
        overflow: "hidden",
      }}
    >
      
        <CardMedia
          component="img"
          height="225"
          image={caseStudy.image}
          alt={caseStudy.title}
          sx={{
            borderRadius: "32px 32px 32px 32px",
            objectFit: "cover",
            padding: 2,
          }}
        />

        {/* Content Section */}
        <Box sx={{ padding: "16px", bottom: 20, left: 20, right: 20 }}>
          <Typography
            variant="h6"
            sx={{
              fontFamily: "Poppins",
              fontWeight: "600",
              fontSize: "18px",
              color: "#000",
              marginBottom: "3px",
            }}
          >
            {caseStudy.title}
          </Typography>

          <Typography
            variant="body2"
            sx={{
              fontFamily: "Poppins",
              fontWeight: "400",
              fontSize: "14px",
              color: "#363636",
              marginBottom: "16px",
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              WebkitLineClamp: 2, 
              textOverflow: 'ellipsis',
            }}
          >
            {caseStudy.description}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {/* Wrapper for image and industry name */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src={caseStudy.icon}
                alt="dropdown arrow"
                style={{ height: "25px", marginRight: "8px" }} // Add margin to create spacing between image and text
              />
              <Typography sx={{color:"#969595", fontFamily:"Poppins", fontWeight:400, fontSize:16}}>{caseStudy.benifits.industry}</Typography>
            </Box>
            
            {/* "Read more" link */}
            <a href={`/ourwork/${caseStudy.url}`} style={{ textDecoration: "none" }}>
              <Typography
                variant="text"
                sx={{
                  fontFamily: "Poppins",
                  fontWeight: "bold",
                  fontSize: "16px",
                  color: "#0BA0E0",
                  padding: 0,
                  mr: 2,
                  fontStyle: "normal",
                  lineHeight: "normal",
                }}
              >
                Read more
              </Typography>
            </a>
          </Box>

        </Box>
     
    </Card>
  );
};

export default OurWork;
