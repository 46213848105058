import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Stack,
  List,
  ListItem,
  IconButton,
  Button,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const ServicesDesktop = ({ servicesData, baseURL="services" }) => {
  const [expanded, setExpanded] = useState(
    Object.values(servicesData.sub)[0]?.id || null
  );

  const [hoveredService, setHoveredService] = useState(
    servicesData.sub[Object.keys(servicesData.sub)[0]]
  );

  const handleHover = (service) => {
    setHoveredService(service);
  };

  const handleToggle = (panel) => () => {
    setExpanded(expanded === panel ? null : panel);
  };

  return (
    <Grid container spacing={4} padding={3} marginTop={5}>
      <Grid item xs={12} sm={6}>
        <Stack>
          <List>
            {Object.entries(servicesData.sub).map(([key, service]) => (
              <ListItem
                key={service.id}
                sx={{
                  cursor: "pointer",
                  padding: 3,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  height: "100px",
                  "&:hover": {
                    bgcolor: "#E0F5FF",
                    color: "#525252",
                    borderRadius: "1100px",
                    height: "100px",
                    padding: 3,
                    "& .arrow-icon": {
                      backgroundColor: "#0BA0E0",
                      color: "white",
                    },
                  },
                }}
                onMouseEnter={() => handleHover(service)}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={service.icon}
                    alt={service.title}
                    style={{
                      width: 50,
                      marginRight: 10,
                    }}
                  />
                  <Typography
                    sx={{
                      color: "#525252",
                      ml: 2,
                      fontWeight: 500,
                      fontSize: "20px",
                      lineHeight: "31px",
                      fontStyle: "normal",
                      fontFamily: "Poppins",
                    }}
                  >
                    {service.title}
                  </Typography>
                </Box>
                <IconButton
                  className="arrow-icon"
                  sx={{
                    color: "inherit",
                    backgroundColor: "white",
                    borderRadius: "50%",
                    padding: "8px",
                    transition: "background-color 0.3s ease, color 0.3s ease",
                  }}
                >
                  <ArrowForwardIosIcon fontSize="small" />
                </IconButton>
              </ListItem>
            ))}
          </List>
        </Stack>
      </Grid>

      <Grid item xs={12} sm={6}>
        {hoveredService ? (
          <Box sx={{ marginTop: 6 }}>
            <Typography
              sx={{
                mb: 2,
                flexGrow: 1,
                fontSize: "26px",
                fontWeight: 600,
                fontFamily: "Poppins",
                fontStyle: "normal",
              }}
            >
              {hoveredService.title}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography
                  sx={{
                    color: "#000",
                    fontSize: "14px",
                    fontWeight: 300,
                    fontFamily: "Poppins",
                    fontStyle: "noraml",
                    mb: 2,
                    lineHeight: "noraml",
                  }}
                >
                  {hoveredService.descripotion_small_servcies}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <img
                  src={hoveredService.image_services}
                  alt={hoveredService.title}
                  style={{
                    width: "100%",
                    maxWidth: "400px",
                    height: "auto",
                    borderRadius: "8px",
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                  }}
                />
              </Grid>
            </Grid>
            <Box sx={{ textAlign: "flex-start" }}>
             {hoveredService.url === '' ? "" : <Button
                href={`/${baseURL}/${hoveredService.url}`}
                variant="text"
                sx={{ textTransform: "none" }}
              >
                Read More <ArrowForwardIosIcon fontSize="inherit" />
              </Button>}
            </Box>
          </Box>
        ) : (
          <Typography variant="h6" sx={{ textAlign: "center" }}>
            Hover over a service to view details
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default ServicesDesktop;
