import React from "react";
import CollaborationButton from "./CollaborationButton";
import collaborationData from "../../constants/collaboration";
import { Box, Grid, Typography } from "@mui/material";

export const Collaboration = () => {
  return (
    <Box
      sx={{
        padding: 2,
        marginRight: { xs: 1, sm: 7 },
        marginLeft: { xs: 1, sm: 7 },
        marginTop: {xs: 2, sm: 5}
      }}
    >
      <Typography
        align="center"
        sx={{
          fontWeight: { xs: 700, sm: 600 },
          fontSize: { xs: "26px", sm: "50px" }, 
          color: "#1D1D1E",
          fontStyle: "normal",
          lineHeight: "normal",
          marginBottom: 2,
        }}
      >
        How we can collaborate?
      </Typography>
      <Typography
        align="center"
        sx={{
          fontSize: { xs: "18px", sm: "26px" },
          fontWeight: 400,
          fontStyle: "normal",
          lineHeight: "normal",
          color: "#363636",
          marginBottom: 4,
        }}
      >
        Ways to engage our professional software services.
      </Typography>
      <Grid container spacing={2} direction="row" justifyContent="center">
        <CollaborationButton collaborationData={collaborationData} />
      </Grid>
    </Box>
  );
};

export default Collaboration;
