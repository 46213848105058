import React from 'react';
import { Box, Container, Typography, Breadcrumbs, Link, Grid } from '@mui/material';
import { Helmet } from 'react-helmet';
import EmailContactForm from './EmailContactForm';
import contactUsBanner from '../../assets/images/contact-us-banner.png';

const ContactUs = () => {
    return (
        <>
        <Helmet>
          <title>PRSTech.io | Contact Us</title>
          <meta name="description" content="Contact Us PRSTech.io" />
          <meta name="keywords" content="Contact Us PRSTech.io" />
          <meta name="author" content="PRShete Technologies Pvt. Ltd." />
        </Helmet>
        {/* <Container maxWidth="lg" sx={{ mt: 10 }}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href="/">
                    Home
                </Link>
                <Typography color="text.primary">Contact</Typography>
            </Breadcrumbs>
        </Container> */}

<Box
        sx={{
          marginRight: { xs: 1, sm: 7 },
          marginLeft: { xs: 1, sm: 7 },
          justifyContent: "center",
          marginTop: 10,
        }}
      >
        <Box
          sx={{
            position: "relative",
            background: `url(${contactUsBanner}) white 50% / cover no-repeat`,
            width: "100%",
            height: { xs: 280, sm: 600 },
            justifyContent: "flex-start",
            textAlign: "left",
            paddingLeft: 3,
            paddingTop: { xs: 2, sm: 5 },
          }}
        >
          <Typography
            variant="h1"
            sx={{
              position: "absolute",
              top: "13%",
              left: "3%",
              fontFamily: "Poppins",
              fontWeight: 500,
              fontSize: { xs: 20, sm: 65 },
              color: "#191919",
              zIndex: 1,
            }}
          >
            Contact Us
          </Typography> 
        </Box>
       
        <Box
  sx={{
    position: 'relative', // Ensures the child element can be absolutely positioned
    bgcolor: 'white',
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    py: 10,
    borderRadius: '44px',
    background: '#FFF',
    boxShadow: '0px 4px 32.9px 0px rgba(172, 171, 171, 0.25)',
    overflow: 'hidden', // Ensures the pseudo-element stays inside the parent
  }}
>
  <Box
    sx={{
      position: 'absolute',
      bottom: '-50px', 
      right: '-50px',  
      width: '400px',  
      height: '400px', 
      borderRadius: '682.524px',
      background: 'radial-gradient(36.59% 36.59% at 50% 50%, rgba(96, 226, 255, 0.40) 0%, rgba(255, 255, 255, 0.20) 100%)',
      filter: 'blur(26.15px)',
      zIndex: 0, 
      marginTop: 5
    }}
  />
  <Container sx={{ position: 'relative', zIndex: 1 }} maxWidth="lg">
    <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <EmailContactForm />
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant="h2"
              sx={{
                fontFamily: 'Montserrat,sans-serif',
                fontWeight: 600,
                color: 'inherit',
                fontSize: { lg: 20, xs: 18 },
                mb: 1,
              }}
            >
              Europe
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="body1" sx={{ mb: 1 }}>
                  Amsterdam
                </Typography>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  Hoofdweg, 1058AW, Amsterdam, Noord Holland, The Netherlands.
                </Typography>
                <Typography variant="body2" sx={{ mb: 2 }}>
                  Phone: +31 616816768
                </Typography>
              </Grid>
              <Grid item xs={6}></Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h2"
              sx={{
                fontFamily: 'Montserrat,sans-serif',
                fontWeight: 600,
                color: 'inherit',
                fontSize: { lg: 20, xs: 18 },
                mb: 1,
              }}
            >
              India
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="body1" sx={{ mb: 1 }}>
                  Pune
                </Typography>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  113, Sai Vision, Pimple Saudagar, Pune, India, 411027
                </Typography>
                <Typography variant="body2" sx={{ mb: 2 }}>
                  Phone: +91 99708 32277
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" sx={{ mb: 1 }}>
                  Pune
                </Typography>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  Wing A, Rose E Mehar, Rahatani, Pune, India, 411017
                </Typography>
                <Typography variant="body2" sx={{ mb: 2 }}>
                  Phone: +91 99708 32277
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Container>
</Box>


        </Box>
        </>
    );
}

export default ContactUs;