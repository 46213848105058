import React, { useState } from "react";
import { Box, Button, Typography, Grid } from "@mui/material";
import Slider from "react-slick"; // Import the react-slick Slider component
import banerImage from "../../assets/images/home-overlay-image.png";
import subtract from "../../assets/images/home-page-banner.svg";
// import mobileSubtract from "../../assets/images/bg-banner-mobile.png";
import mobileSubtract from '../../assets/images/mobile-banner-svg.svg';
import mobileBanerImage from "../../assets/images/mobile-robot-img.svg";
import { useTranslation } from "react-i18next";
import bookCallIcon from "../../assets/images/book-call-icon.svg";
import banner2 from "../../assets/images/banner2.png";
import banner3 from "../../assets/images/banner3.png";
import mobileBanner2 from '../../assets/images/mobile_banner_2.png';
import mobileBanner3 from '../../assets/images/mobile_banner_3.png'

const Banner = () => {
  const { t } = useTranslation();
  const homeData = t("home", { returnObjects: true });
  const [currentPage, setCurrentPage] = useState(0);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    pauseOnHover: false,
    beforeChange: (current, next) => {
      setCurrentPage(next);
    },
    customPaging: (i) => {
      return (
        <Box
          sx={{
            width: 12,
            height: 12,
            borderRadius: "50%",
            backgroundColor: currentPage === i ? "#019AE5" : "#C4C4C4",
            margin: "15px 5px",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "#019AE5",
            },
          }}
        />
      );
    },
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          autoplay: false, 
        },
      },
      {
        breakpoint: 480, 
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
        },
      },
    ],
  };
  

  return (
    <Box
      sx={{
        marginRight: { xs: 1, sm: 7 },
        marginLeft: { xs: 1, sm: 7 },
        marginTop: 10,
        height: { xs: 'auto', sm: 677 },
      }}
    >
     <Box sx={{ display: { xs: "none", sm: "block" } }}>
  <Slider {...settings}>
    {homeData.banner.map((banner, index) => (
      <Box
        key={index}
        sx={{
          position: "relative",
          height: { xs: 280, sm: 637 },
          display: "flex",
          justifyContent: "center",
          textAlign: "flex-start",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            position: "relative",
          }}
        >
          {/* Background Image */}
          <img
            src={subtract}
            alt={`Background ${index + 1}`}
            style={{
              position: "absolute",
              width: "100%",
              height: 594,
              top: 0,
              left: 0,
            }}
          />

          {/* Grid Layout */}
          <Grid container sx={{ height: "100%", position: "relative" }}>
            {/* Left Side */}
            <Grid
              item
              xs={12}
              sm={8}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                padding: 4,
                zIndex: 2,
              }}
            >
               <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontWeight: 300,
                  fontSize: 36,
                  color: "#000000",

                }}
              >
                {banner.caption}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontWeight: 500,
                  fontSize: 65,
                  color: "#191919",
                  marginBottom: 5
                }}
              >
                {banner.title}
              </Typography>
             
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontWeight: "normal",
                  fontSize: 24,
                  color: "black",
                  marginTop: 2,
                }}
              >
                <b>{banner.question}</b>
                <br />
                {banner.description}
              </Typography>
              <Button
                href="/bookcall"
                variant="contained"
                sx={{
                  marginTop: 2,
                  width: 251,
                  height: 66,
                  backgroundColor: "#011b6e",
                  borderRadius: 33,
                  fontFamily: "Poppins, Helvetica",
                  fontWeight: "semiBold",
                  fontSize: 20,
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                BOOK CALL
                <img
                  src={bookCallIcon}
                  alt="book-call arrow"
                  style={{ width: 16, height: 16, marginLeft: 10 }}
                />
              </Button>
            </Grid>

            {/* Right Side */}
            <Grid
              item
              xs={12}
              sm={4}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                padding: 4,
                position: "relative",
              }}
            >
              <img
                src={index === 0 ? mobileBanerImage : index === 1 ? mobileBanner2 : mobileBanner3} 
                alt={`Banner ${index + 1}`}
                style={{
                  position: "absolute",
                  width: '80%',
                  height: '86%',
                  right: '12%',
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    ))}
  </Slider>
</Box>

      {/* Mobile Box (Only visible on xs screens) */}
      <Box
        sx={{
          marginBottom: 5,
          display: { xs: "block", sm: "none" },
        }}
      >
        <Slider {...settings}>
          {homeData.banner.map((item, index) => (
            <Box
              key={index}
              sx={{
                position: "relative",
                display: "flex",
                height: '666px',
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                backgroundImage: `url(${mobileSubtract})`,
                backgroundSize: "cover",
                textAlign: "left",
                padding:1
              }}
            >
              {/* Text Section */}
              <Box sx={{ width: "63%", height: '23%', textAlign: "left" }}>
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: 400,
                    fontSize: "20px",
                    color: "#000",
                    fontStyle: 'normal',
                    // marginTop: "5px", 
                    // marginBottom: "8px",
                  }}
                >
                  {item.caption}
                </Typography>

                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "28px",
                    color: "#191919",
                    // marginTop: "8px", // Added spacing for the title
                    // marginBottom: "16px",
                  }}
                >
                  {item.title}
                </Typography>

              </Box>
              <Box sx={{ width: "90%",height: '25%', textAlign: "left", ml:4, }}>
              <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "24px",
                    color: "#000",
                    lineHeight: "normal",
                    // marginBottom: "20px",
                  }}
                >
                  {item.description}
                </Typography>
              </Box>

              {/* Banner Image */}
              <Box
        sx={{
          width: "100%",
          margin: "10px auto",
          display: "flex",
          textAlign: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={index === 0 ? mobileBanerImage : index === 1 ? mobileBanner2 : mobileBanner3 }
          alt="Banner"
          style={{
            width: "100%",
            maxWidth: "212px",
            height: "auto",
            objectFit: "contain",
          }}
        />
      </Box>

              {/* Button */}
            </Box>
          ))}
        </Slider>
       
       <Box sx={{
                  marginTop: "35px", 
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: "center",
                }}>
        <Button
                href="/bookcall"
                variant="contained"
                sx={{
                  marginTop: "30px", 
                  backgroundColor: "#011B6E",
                  borderRadius: "38.5px",
                  fontFamily: "Poppins",
                  fontWeight: 600,
                  fontSize: "27.416p",
                  color: "white",
                  justifyContent: "center",
                  width: "50%",
                  height: "60px",
                }}
              >
                BOOK CALL
                <img
                  src={bookCallIcon}
                  alt="book-call arrow"
                  style={{
                    width: 16,
                    height: 16,
                    marginLeft: 10,
                  }}
                />
              </Button>
              </Box>
      </Box>
    </Box>
  );
};

export default Banner;
