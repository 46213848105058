import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import { IconButton, Menu, MenuItem, Typography } from '@mui/material';
import dropdownArrow from '../../assets/images/mobile-down-arrow.svg';
import enLanguage from '../../assets/images/enLanguage.svg';
import deLanguage from '../../assets/images/deLanguage.svg';
import frLanguage from '../../assets/images/frLanguage.svg';
import data from '../../constants/data';

export default function SwipeableTemporaryDrawer() {
  const [state, setState] = React.useState(false);
  const [expanded, setExpanded] = React.useState({});
  const [languageAnchorEl, setLanguageAnchorEl] = React.useState(null);
  const [selectedLanguage, setSelectedLanguage] = React.useState('English');

  const toggleDrawer = (value) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setState(value);
  };

  const toggleExpansion = (category, event) => {
    event.stopPropagation();
    setExpanded((prev) => ({
      ...prev,
      [category]: !prev[category],
    }));
  };

  const handleLanguageMenuOpen = (event) => {
    setLanguageAnchorEl(event.currentTarget);
  };

  const handleLanguageMenuClose = () => {
    setLanguageAnchorEl(null);
  };

  const handleLanguageChange = (lang) => {
    setSelectedLanguage(lang);
    handleLanguageMenuClose();
  };

  return (
    <div>
      <React.Fragment>
        <IconButton onClick={toggleDrawer(true)}>
          <MenuIcon />
        </IconButton>

        <SwipeableDrawer
          open={state}
          anchor="right"
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
          sx={{
            width: 300,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: 300,
              padding: '20px',
              backgroundColor: '#011B6E',
            },
          }}
        >
          <Box
            sx={{ width: 'auto' }}
            role="presentation"
            onClick={(event) => event.stopPropagation()}
            onKeyDown={(event) => event.stopPropagation()}
          >
            <List>
              {Object.keys(data).map((page) => {
                const isServicesOrIndustries = page === 'services' || page === 'industries';
                const menuItems = isServicesOrIndustries
                  ? page === 'services'
                    ? data.services.sub
                    : data.industries.sub
                  : null;

                const mainItemUrl = data[page].url === '' ? '/' : `/${data[page].url}`;

                return (
                  <div key={page}>
                    <ListItemButton
                      component="a"
                      href={isServicesOrIndustries ? null : mainItemUrl} 
                      onClick={isServicesOrIndustries ? (event) => toggleExpansion(page, event) : null}
                    >
                      <ListItemText
                        primary={page.charAt(0).toUpperCase() + page.slice(1)}
                        sx={{
                          color: '#67C9F3',
                          fontWeight: 700,
                          fontSize: 18,
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      />
                      {isServicesOrIndustries && (
                        <img
                          src={dropdownArrow}
                          alt="dropdown arrow"
                          style={{
                            width: 16,
                            height: 16,
                            marginLeft: 8,
                            transform: expanded[page] ? 'rotate(180deg)' : 'rotate(0)',
                            color: '#67C9F3',
                          }}
                        />
                      )}
                    </ListItemButton>

                    {expanded[page] && menuItems && (
                      <Box sx={{ pl: 2 }}>
                        {Object.entries(menuItems).map(([subKey, subValue]) => {
                          const itemUrl = `/${page}/${subValue.url}`;
                          return (
                            <ListItem key={subKey} disablePadding>
                              <ListItemButton component="a" href={itemUrl}>
                                <ListItemText
                                  primary={subKey}
                                  sx={{
                                    color: '#67C9F3',
                                    fontSize: 13,
                                    fontWeight: 600,
                                  }}
                                />
                              </ListItemButton>
                            </ListItem>
                          );
                        })}
                      </Box>
                    )}
                  </div>
                );
              })}
            </List>

            {/* Language Dropdown */}
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 10 }}>
              <Box sx={{ border: '1px solid #FFF', borderRadius: '5px', padding: 1 }}>
                <Typography
                  variant="body1"
                  sx={{ color: '#67C9F3', fontSize: '16px', fontWeight: 400 }}
                  // onClick={handleLanguageMenuOpen}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', fontWeight: 400 }}>
                    <img
                      src={
                        selectedLanguage === 'English'
                          ? enLanguage
                          : selectedLanguage === 'German'
                          ? deLanguage
                          : frLanguage
                      }
                      alt={selectedLanguage}
                      style={{ width: 20, height: 20, marginRight: 10 }}
                    />
                    {selectedLanguage}
                    <img
                      src={dropdownArrow}
                      alt="dropdown arrow"
                      style={{
                        width: 15,
                        marginLeft: 8,
                        transform: 'rotate(180deg)',
                        color: '#67C9F3',
                      }}
                    />
                  </Box>
                </Typography>
              </Box>

              <Menu
                anchorEl={languageAnchorEl}
                open={Boolean(languageAnchorEl)}
                onClose={handleLanguageMenuClose}
                sx={{
                  '& .MuiMenu-paper': {
                    width: '130px',
                    borderRadius: '13px',
                    border: '1px solid #FFF',
                    backgroundColor: '#011B6E',
                    boxShadow: '0px 4px 11.2px 0px rgba(135, 135, 135, 0.25)',
                  },
                }}
              >
                <MenuItem onClick={() => handleLanguageChange('English')} selected={selectedLanguage === 'English'}>
                  <Box sx={{ display: 'flex', alignItems: 'center', color: '#67C9F3', fontSize: 14, fontWeight: 600 }}>
                    <img
                      src={enLanguage}
                      alt="English"
                      style={{ width: 25, height: 25, marginRight: 10 }}
                    />
                    English
                  </Box>
                </MenuItem>
                <MenuItem onClick={() => handleLanguageChange('German')} selected={selectedLanguage === 'German'}>
                  <Box sx={{ display: 'flex', alignItems: 'center', color: '#67C9F3', fontSize: 14, fontWeight: 600 }}>
                    <img
                      src={deLanguage}
                      alt="German"
                      style={{ width: 25, height: 25, marginRight: 10 }}
                    />
                    German
                  </Box>
                </MenuItem>
                <MenuItem onClick={() => handleLanguageChange('French')} selected={selectedLanguage === 'French'}>
                  <Box sx={{ display: 'flex', alignItems: 'center', color: '#67C9F3', fontSize: 14, fontWeight: 600 }}>
                    <img
                      src={frLanguage}
                      alt="French"
                      style={{ width: 25, height: 25, marginRight: 10 }}
                    />
                    French
                  </Box>
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}
