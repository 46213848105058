import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Stack,
  Button,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import data from "../../constants/data";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const ServicesMobile = ({servicesData, baseURL="services"}) => {

  const [expanded, setExpanded] = useState(
    Object.values(servicesData.sub)[0]?.id || null
  );

  const handleToggle = (panel) => () => {
    setExpanded(expanded === panel ? null : panel);
  };

  return (
    <Stack spacing={1}>
    {Object.entries(servicesData.sub).map(([key, service]) => (
      <Accordion
        key={service.id}
        expanded={expanded === service.id}
        onClick={handleToggle(service.id)}
        sx={{
          borderRadius: "8px",
          border: "1px solid #ddd",
          bgcolor: 'inherit',
          boxShadow: expanded === service.id ? "0 4px 8px rgba(0, 0, 0, 0.1)" : "none",
          transition: "background-color 0.3s ease",
        }}
      >
        <AccordionSummary
          aria-controls={`${service.id}-content`}
          id={`${service.id}-header`}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",                 
            bgcolor: expanded === service.id ? "#E0F5FF" : "#F9F9F9", 
            color: "#525252", 
            borderRadius: "8px", 
            transition: "all 0.3s ease", 
            
          }}
          expandIcon={
            
            <ExpandMoreIcon
              sx={{
                color: expanded === service.id ? "#FFFFFF" : "#525252",
                fontSize: "2.2rem",
                backgroundColor: expanded === service.id ? "#0BA0E0" : "#FFFFFF",
                borderRadius: "50%",
                padding: "8px",
                transition:
                  "background-color 0.3s ease, color 0.3s ease",
                
              }}
            />
          }
        >
          <Typography variant="h6" component="div" sx={{ fontWeight: 500 }}>
          <img
                    src={service.icon}
                    alt="service "
                    style={{
                      width: 22,
                      mr:10
                    }}
                  />  {service.title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            px: 2,
            py: 1.5,
            display: "flex", 
            flexDirection: "column", 
            justifyContent: "center", 
            alignItems: "center", 
          }}
        >
          <img
            src={service.image_services}
            alt={service.title}
            style={{
              width: "100%",
              maxWidth: "200px",
              height: "auto",
              borderRadius: "8px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            }}
          />
          <Typography variant="body2" sx={{ my: 2, textAlign: "left" }}>
            {service.descripotion_small_servcies}
          </Typography>

          <Box sx={{ textAlign: "left" }}> 
          {service.url === '' ? "" : <Button
               href={`/${baseURL}/${service.url}`}
                variant="text"
                sx={{ textTransform: "none", textAlign: "left"  }}
              >
                Read More <ArrowForwardIosIcon fontSize="inherit" />
              </Button>}
          </Box>
        </AccordionDetails>

      </Accordion>
    ))}
  </Stack>
  );
};

export default ServicesMobile;
