import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Stack,
} from "@mui/material";
import { Helmet } from "react-helmet";
import data from "../../constants/data";
import { useParams } from "react-router-dom";
import OurWorksCarousel from "../ourworks/OurWorksCarousel";
import ResourcesCarousel from "../resources/ResourcesCarousel";

import ServicesDesktop from "../common/ServicesDesktop";
import ServicesMobile from "../common/ServicesMobile";
import BlueContainer from "../common/BlueContainer";

const ServicePage = () => {
  const { id } = useParams();

  const ourworkData = data.ourwork;
  const resourcesData = data.resources;
  const subServicesData = data.services.sub;
  const service = Object.values(subServicesData).find(
    (item) => item.url === id
  );

  const [hoveredService, setHoveredService] = useState(
    service.sub[Object.keys(service.sub)[0]]
  );

  const handleHover = (service) => {
    setHoveredService(service);
  };

  const [expanded, setExpanded] = useState(null);

  const [expandedMobile, setExpandedMobile] = useState(Object.values(service.sub)[0]?.id || null);


  const handleToggle = (panel) => () => {
    setExpandedMobile(expandedMobile === panel ? null : panel);
  };

  return (
    <>
      <Helmet>
        <title>Prshete.com | Services </title>
        <meta
          name="description"
          content="Freelancer Python Backend Developer"
        />
        <meta
          name="keywords"
          content="Freelancer Python Backend Developer, Python Remote Backend Developer"
        />
        <meta name="author" content="Purushottam Shete" />
      </Helmet>

      <Box
        sx={{
          marginRight: { xs: 1, sm: 7 },
          marginLeft: { xs: 1, sm: 7 },
          justifyContent: "center",
          marginTop: 10,
        }}
      >
         <Box
          sx={{
            position: "relative",
            background: `url(${service.image_banner}) white 50% / cover no-repeat`,
            width: "100%",
            height: { xs: 188, sm: 600 },
            justifyContent: "flex-start",
            textAlign: "left",
            paddingLeft: 3,
            paddingTop: { xs: 2, sm: 5 },
          }}
        >
          <Typography
            variant="h1"
            sx={{
              position: "absolute",
              top: "13%",
              left: "3%",
              fontFamily: "Poppins",
              fontWeight: 500,
              fontSize: { xs: 20, sm: 44 },
              color: "#191919",
              zIndex: 1,
            }}
          >
            {service.title}
          </Typography> 
        </Box>

        <Box sx={{ width: "100%", mt:{xs:4, sm:8} }}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12} sm={6}>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontWeight: 300,
                  fontSize: {xs:24, sm:40},
                  color: "#595757",
                  lineHeight: {xs:'30px',sm:"50px"},
                  fontStyle: "normal",
                }}
              >
                {service.description_banner_left}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} sx={{mt:{xs:4,sm:0}}}>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontWeight: 300,
                  fontSize: 16,
                  color: "#000",
                  lineHeight: "30px",
                  fontStyle: "normal",
                }}
              >
                {service.description_banner_right}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Stack sx={{ alignItems: "center", mx: {xs:0,sm:4}, mt: 6 }}>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontWeight: { xs: 700, sm: 600 },
              fontSize: { xs: 26, sm: 50 },
              color: "#000",
              textAlign: "center",
              mb: 1,
              lineHeight: "normal",
              fontStyle: "normal",
            }}
          >
            {service.title}
          </Typography>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: { xs: "18px", sm: "26px" },
              fontWeight: 400,
              color: "#363636",
              textAlign: "center",
              lineHeight: "normal",
              fontStyle: "normal",
            }}
          >
            {service.descripotion_small_servcies}
          </Typography>
        </Stack>
        <Box
          sx={{
            backgroundColor: "#F9F9F9",
            borderRadius: "80px",
            marginBottom: 8,
            display: {xs: 'none', sm: 'block'}
          }}
        >
          <ServicesDesktop servicesData={service} />
        </Box>

        <Box sx={{ width: "100%", bgcolor: "#f9f9f9", display: { xs: 'block', sm: 'none' }, mt:5 }}>
           <ServicesMobile servicesData={service} />
      </Box>
       
      </Box>
      <OurWorksCarousel data={ourworkData} />
      <ResourcesCarousel data={resourcesData} />
      <BlueContainer/>
    </>
  );
};

export default ServicePage;
